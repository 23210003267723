import React from "react";
import { Box, Grid } from "@mui/material";
import MySelect from "../../../../../components/MySelect";
import classes from "./PreviewAnouncement.module.css";
import MyInput from "../../../../../components/MyInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import EditButton from "../../../../../components/EditButton";
import AnnouncementCard from "../../../../../components/AnnouncementCard";

const PreviewAnouncement = ({ data, handleDelete, handleEdit }) => {
  console.log(data);
  return (
    <>
      <div className={classes["headerActions"]}>
        <MyCheckBox
          name={"active"}
          value={data?.active}
          label={data?.active ? "Active" : "InActive"}
        />
        <div className={classes["actionsWrapper"]}>
          <DeleteButton handler={handleDelete} />
          <EditButton handler={() => handleEdit(data)} />
        </div>
      </div>
      {data && <AnnouncementCard data={data} />}
    </>
  );
};

export default PreviewAnouncement;
