import PaddingContainer from "../../../../components/PaddingContainer";
const UserAccess = () => {
  return (
    <PaddingContainer>
      <h2>User</h2>
    </PaddingContainer>
  );
};

export default UserAccess;
