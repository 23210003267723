import React, { useState, useEffect } from "react";
import styles from "./LoginStep1.module.css";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../../components/MyInput";
import MyButton from "../../../../components/MyButton";
import MyPhoneCode from "../../../../components/MyPhoneCode";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

const LoginStep1 = ({ buttonHanler, login }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className={styles.header}>
        <h1>Welcome Back</h1>
        <h2>
          Login below or <span onClick={buttonHanler}>Create account</span>
        </h2>
      </div>
      <Box
        component="form"
        onSubmit={login.form.handleSubmit}
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
        className={styles.formComponent}
        noValidate
        autoComplete="off"
      >
        <div style={{ marginTop: "5%" }}>
          <Grid container columnGap={1}>
            <Grid sm={2.5} xs={12}>
              {/* <MyInput
                name="code"
                label="C-Code"
                value={login?.form?.values?.code}
                setter={login?.form.handleChange("code")}
                error={login.form.errors.code}
              /> */}
              <MyPhoneCode
                name="code"
                label="C-Code"
                value={login?.form?.values?.code}
                setter={login?.form.handleChange("code")}
                error={login.form.errors.code}
              />
            </Grid>
            <Grid sm={9} xs={12}>
              <MyInput
                name="phoneNumber"
                label="Number"
                value={login?.form?.values?.phoneNumber}
                setter={login?.form.handleChange("phoneNumber")}
                error={login.form.errors.phoneNumber}
              />
            </Grid>
          </Grid>
          <MyInput
            name="password"
            label="Password"
            value={login?.form?.values?.password}
            setter={login?.form.handleChange("password")}
            error={login.form.errors.password}
            type={showPassword ? "text" : "password"}
            leftIcon={
              showPassword ? (
                <FaLockOpen
                  color="var(--main-color-dark)"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <FaLock
                  color="var(--main-color-dark)"
                  style={{ cursor: "pointer" }}
                />
              )
            }
            leftIconHandler={() => setShowPassword(!showPassword)}
          />
        </div>
        <div className={styles.btnWrapper}>
          <MyButton
            btnText="Sign In"
            type="submit"
            className={styles.extraBtnStyle}
            disabled={login.loading}
          />
        </div>
      </Box>
    </>
  );
};

export default LoginStep1;
