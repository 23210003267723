import React, { useState } from "react";
import classes from "./UploadFile.module.css";
import "./UploadFile.css";
import { BiCloudUpload } from "react-icons/bi";
import MyButton from "../../components/MyButton";
import { MdOutlineCancel } from "react-icons/md";
import { toast } from "react-toastify";
import { Modal } from "@mui/material";
import { csv } from "../../assets/constant";

function UploadFile({ show, setShow, handler }) {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const formData = new FormData();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast.warning("File size must be less than 5MB");
        return;
      }
      setFile(selectedFile);
      simulateUploadProgress(selectedFile);

      // const reader = new FileReader();
      // reader.readAsDataURL(selectedFile);
      // reader.onload = function () {
      //   const base64String = reader.result;

      // };
      // reader.onerror = function (error) {
      //   console.error("Error: ", error);
      // };
    }
  };

  const simulateUploadProgress = (uploadedFile) => {
    const totalSize = uploadedFile?.size;
    let uploadedBytes = 0;
    const progressInterval = setInterval(() => {
      uploadedBytes += Math.random() * 1000;
      const progress = Math.min((uploadedBytes / totalSize) * 100, 100);
      setUploadProgress(progress);
      if (progress === 100) {
        clearInterval(progressInterval);
      }
    }, 200);
  };

  const handleUploadClick = () => {
    document.getElementById("file-input").click();
  };

  const handleFileUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      await handler(formData);
    }
  };

  return (
    <Modal
      open={show}
      onClose={setShow}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="container">
        <div className="heading-wrapper">
          <h1>Upload</h1>
          <p>Upload your Profile picture here</p>
        </div>
        <div className="upload-area" onClick={handleUploadClick}>
          <BiCloudUpload
            size={100}
            color={"var(--main-color)"}
            style={{ strokeWidth: "0" }}
          />
          <input
            id="file-input"
            type="file"
            onChange={handleFileChange}
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{ display: "none" }}
          />
          <p>Click here to upload</p>
          <p>Max 5 MB files are allowed</p>
        </div>
        {file && (
          <div className="file-item">
            <div className="file-icon">
              <img src={csv} alt="" />
            </div>
            <div className="progress-bar">
              <div
                className="progress"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <div className={classes["cancel-icon"]}>
              <MdOutlineCancel
                size={25}
                color={"var(--red-color)"}
                onClick={() => {
                  setFile(null);
                  setUploadProgress(0);
                }}
              />
              <p>{Math.round(uploadProgress)}%</p>
            </div>
          </div>
        )}
        <div className={classes["action-wrapper"]}>
          <MyButton
            btnText="Cancel"
            type="button"
            className={classes["cancel-button"]}
            handler={() => {
              setFile(null);
              setUploadProgress(0);
              setShow();
            }}
          />

          <MyButton
            label="Confirm"
            handler={handleFileUpload}
            className={classes["signIn-button"]}
            disabled={uploadProgress !== 100}
          >
            Confirm
          </MyButton>
        </div>
      </div>
    </Modal>
  );
}

export default UploadFile;
