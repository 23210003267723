import React from "react";
import { Outlet } from "react-router-dom";
import SidebarSkeleton from "./SidebarSkeleton";
import ParentSkeleton from "./ParentSkeleton";

const UserLayout = () => {
  return (
    <div className="userLayoutWrapper">
      <ParentSkeleton>
        <Outlet />
      </ParentSkeleton>
    </div>
  );
};

export default UserLayout;
