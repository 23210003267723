import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useApprovals from "../../../../Hooks/useApprovals";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import ApprovalCard from "../../../../components/ApprovalCard";
import FilterButton from "../../../../components/FilterButton";
import Header from "../../../../components/Header";
import Loader from "../../../../components/Loader";
import PaddingContainer from "../../../../components/PaddingContainer";
import classes from "./Approvals.module.css";
import PreviewApproval from "./PreviewApproval";

const Approvals = () => {
  const approval = useApprovals();
  const generalApis = useGeneralApi();
  const { loading } = useSelector((state) => state?.loadingReducer);
  const [openPreview, setOpenPreview] = useState(false);

  const handlePreview = (id) => {
    approval.getApprovalById(id);
    setOpenPreview(true);
  };

  useEffect(() => {
    approval?.getAllApprovals();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PaddingContainer>
          {!openPreview ? (
            <>
              <Header title={"Approvals"} />
              <div className={classes["headerWrapper"]}>
                <div className={classes["tabs-section"]}>
                  <FilterButton text={"Pending"} active={true} />
                  <FilterButton text={"In Progress"} />
                  <FilterButton text={"Close"} />
                </div>
              </div>
              <div className={classes["subSection"]}>
                {/* <h1 className={classes["sub-heading"]}>Approvals</h1> */}
                {approval?.approvalsList?.map((item, index) => (
                  <ApprovalCard
                    data={item}
                    isSetting={true}
                    handler={handlePreview}
                    handleReject={() => {
                      approval?.setApprovalStatus(3);
                      approval?.approvalPreviewForm.handleSubmit();
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <PreviewApproval
              form={approval.approvalPreviewForm}
              name="Approval Form"
              roleList={generalApis.roleList}
              typeList={generalApis?.propertyTypeList}
              disabled={true}
              closePreview={() => setOpenPreview(false)}
              handleAccept={() => approval?.setApprovalStatus(2)}
              handleReject={() => approval?.setApprovalStatus(3)}
            />
          )}
        </PaddingContainer>
      )}
    </>
  );
};

export default Approvals;
