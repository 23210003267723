import React from "react";
import styles from "./SignUpInitialStep.module.css";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../../components/MyInput";
import MyButton from "../../../../components/MyButton";
import { hidePhoneNumber } from "../../../../helpers";
import { FaChevronRight } from "react-icons/fa";
import CommonCard from "../../../../components/CommonCard";
import { FaCircleArrowLeft } from "react-icons/fa6";

const SignUpInitialStep = ({
  joinCommunityHandler,
  createCommunityHandler,
  skipHandler,
  goBackHandler,
}) => {
  return (
    <>
      <CommonCard className={[styles.mainWrapper].join(" ")}>
        <div style={{ margin: "20px 0px" }} className="c-p">
          <FaCircleArrowLeft
            color={"var(--main-color)"}
            size={40}
            onClick={goBackHandler}
          />
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 0, ml: 0, width: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
          className={styles.formComponent}
          noValidate
          autoComplete="off"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 15,
              height: "60%",
            }}
          >
            <div className={styles.header}>
              <h1>Please select any one option from bottom. </h1>
            </div>
            <MyButton
              className={styles.extraBtnStyle}
              handler={createCommunityHandler}
            >
              <span className={styles.btnHeading}>Create Community</span>
              <FaChevronRight />
            </MyButton>
            <MyButton
              className={styles.extraBtnStyle}
              handler={joinCommunityHandler}
            >
              <span className={styles.btnHeading}>Join Community</span>
              <FaChevronRight />
            </MyButton>
          </div>
          <div className={styles.skipBtnWrapper}>
            <MyButton
              btnText="Skip"
              className={styles.skipBtn}
              handler={skipHandler}
            />
          </div>
        </Box>
      </CommonCard>
    </>
  );
};

export default SignUpInitialStep;
