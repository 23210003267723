import React from "react";
import styles from "./AmenitiesItem.module.css";
import { FaCheck } from "react-icons/fa6";
import { HiPlus } from "react-icons/hi";

const AmenitiesItem = ({ selected, handler, data }) => {
  return (
    <div
      className={[styles.wrapper, selected && styles.selected].join(" ")}
      onClick={() => handler(data)}
    >
      {data?.ammenityName} {selected ? <FaCheck /> : <HiPlus />}
    </div>
  );
};

export default AmenitiesItem;
