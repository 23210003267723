import React, { useState } from "react";
import classes from "./Dashboard.module.css";
import MainDashboard from "./MainDashboard";
import AmenitiesDashboard from "./AmenitiesDashboard";

const Dashboard = () => {
  const [filter, setFilter] = useState({ value: "month", label: "Month" });
  const setCommunityHandler = (value) => {
    console.log(value);
  };

  const complainData = {
    complainNo: "005525",
    date: "12 Jan 2024",
    userAddress: "Flat/301 - Block A - 1st Floor",
    username: "Haris Ali",
    complainTitle: "Water Leakage",
    complainDescription:
      "Dear Unionis simply dummy text of the printing and typesetting industry...",
  };
  const announcementData = {
    status: "Active",
    date: "12 Jan 2024",
    announcementTitle: "Pay Maintenance on time",
    announcementDescription:
      "Dear owners/Tenants,  Please pay maintenance as soon as possible because...",
  };
  return (
    <>
      <MainDashboard
        complainData={complainData}
        announcementData={announcementData}
        filter={filter}
        setCommunityHandler={setCommunityHandler}
      />
    </>
  );
};

export default Dashboard;
