import React from "react";
import MyInput from "../../../../../components/MyInput";
import { Box, Grid } from "@mui/material";
import classes from "./ProfileSettings.module.css";

const ProfileSettings = ({ form, handleChange }) => {
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MyInput
          name="name"
          label="Name"
          value={form?.name}
          setter={handleChange}
        />
        <Grid container columnGap={4}>
          <Grid xs={2.5}>
            <MyInput
              name="code"
              label="C-Code"
              value={form?.code}
              setter={handleChange}
            />
          </Grid>
          <Grid xs={9}>
            <MyInput
              name="phoneNumber"
              label="Number"
              value={form?.phoneNumber}
              setter={handleChange}
            />
          </Grid>
        </Grid>
        <MyInput
          name="email"
          label="Email Address"
          value={form?.email}
          setter={handleChange}
        />
      </Box>
    </>
  );
};

export default ProfileSettings;
