import React from "react";
import { Box, Grid } from "@mui/material";
import MySelect from "../../../../../components/MySelect";
import classes from "./AddAnouncement.module.css";
import MyInput from "../../../../../components/MyInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";

const AddAnouncement = ({ form, handleChange, handleDelete }) => {
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <div className={classes["headerActions"]}>
          <MyCheckBox
            name={"active"}
            value={form?.active}
            setter={(e) => handleChange("active", e.target.checked)}
            label={form?.active ? "Active" : "InActive"}
          />
          <DeleteButton handler={handleDelete} />
        </div>
        <DatePicker
          name="date"
          label="Announcement Date"
          //   value={form?.date}
          setter={(e) => handleChange("date", e.target.value)}
        />
        <MyInput
          name="title"
          label="Announcement title"
          value={form?.title}
          setter={(e) => handleChange("title", e.target.value)}
        />
        <MyInput
          name="description"
          label="Announcement description"
          value={form?.description}
          setter={(e) => handleChange("description", e.target.value)}
        />
        <MySelect
          name={"sendTo"}
          value={form.sendTo}
          label="Send To"
          setter={(val) => handleChange("sendTo", val)}
          options={null}
        />
        <DatePicker
          name={"validTill"}
          //   value={form.validTill}
          label="Announcement End Date"
          setter={(e) => handleChange("validTill", e.target.value)}
        />
      </Box>
    </>
  );
};

export default AddAnouncement;
