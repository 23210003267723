import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { community, login } from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";

const useMember = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState([]);
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    getAllMemberData();
  }, []);

  const filteredDataHandler = async (data) => {
    dispatch(loadingHandler(true));
    var filtered = {};
    for (var i = 0; i < data?.length; i++) {
      if (data[i].ammenityName in filtered) {
        filtered = {
          ...filtered,
          [data[i].ammenityName]: [...filtered[data[i].ammenityName], data[i]],
        };
      } else {
        filtered = { ...filtered, [data[i].ammenityName]: [data[i]] };
      }
    }
    await setFilteredData(Object.entries(filtered));
    dispatch(loadingHandler(false));
  };

  const memberData = [
    {
      name: "Muhammad Owais",
      flatNo: "308",
      block: "A",
      floor: "3rd",
      type: "Owner",
      code: "",
      contact: "",
    },
    {
      name: "Haris Ali",
      flatNo: "408",
      block: "B",
      floor: "4th",
      type: "Tenant",
      code: "",
      contact: "",
    },
  ];

  const getAllMemberData = async () => {
    dispatch(loadingHandler(true));
    await setMemberList(memberData);
    dispatch(loadingHandler(false));
  };

  return { filteredDataHandler, filteredData, memberList };
};

export default useMember;
