import React, { useState, useEffect } from "react";
import styles from "./Step2.module.css";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../components/MyInput";
import MyButton from "../../../components/MyButton";
import { hidePhoneNumber } from "../../../helpers";
import { FaCircleArrowLeft } from "react-icons/fa6";
import MySelect from "../../../components/MySelect";
import { RiDeleteBin6Line } from "react-icons/ri";
import CommonCard from "../../../components/CommonCard";
import classes from "../OnBoarding.module.css";
import AmenitiesItem from "../../../components/AmenitiesItem";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import AmenitiesComponent from "../../../components/AmenitiesComponent";
import AmenitiesRightComponent from "../../../components/AmenitiesRightComponent";
import LayoutPreviewCard from "../../../components/LayoutPreviewCard";

const Step2 = ({ handleSubmit, goBackHandler, form, loading }) => {
  return (
    <>
      {!loading && (
        <CommonCard
          className={[
            classes.mainWrapper,
            classes.extraStyles,
            styles.customCard,
          ].join(" ")}
          style={{ flexDirection: "row" }}
        >
          <div className={styles.subComponent1}>
            <div style={{ margin: "20px 0px" }} className="c-p">
              <FaCircleArrowLeft
                color={"var(--main-color)"}
                size={35}
                onClick={goBackHandler}
              />
            </div>

            <Box
              sx={{
                "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                position: "relative",
                gap: "10px",
              }}
              className={styles.formComponent}
            >
              <div
                className={[styles.formItem, styles.communityNameWrapper].join(
                  " "
                )}
              >
                <MyInput
                  name="communityName"
                  label="Community Name"
                  value={form?.communityName}
                  disabled={true}
                />
              </div>

              <div className={styles.formItem}>
                <h1>Review your series format of {form?.communityName}</h1>
              </div>
              {/* <div>
                <LayoutPreviewCard data={form?.communityAmmenityList[0]} />
              </div> */}
            </Box>
          </div>
          {/* <hr className={styles.hrStyles} /> */}
          <div>
            <div className={styles.subComponent2}>
              {form?.communityAmmenityList?.map((item, index) => (
                <LayoutPreviewCard data={item} />
              ))}
            </div>
            <div className={styles.btnWrapper}>
              <MyButton
                btnText="Save"
                className={styles.extraBtnStyle}
                disabled={loading}
                handler={handleSubmit}
              />
            </div>
          </div>
        </CommonCard>
      )}
    </>
  );
};

export default Step2;
