import React, { useState } from "react";
import classes from "./Notifications.module.css";
import Grid from "@mui/material/Grid";
import NotificationComponent from "./NotificationComponent";
import Loader from "../../../../components/Loader";
import PaddingContainer from "../../../../components/PaddingContainer";
import Header from "../../../../components/Header";
import { useSelector } from "react-redux";
import { loadingHandler } from "../../../../store/loadingSlice";

const Notifications = () => {
  const { loading } = useSelector((state) => state?.loadingReducer);

  const [notificationsList, setNotificationsList] = useState([
    {
      image: "",
      message:
        "Dear Doctor, We would like to inform you about a new referral criteria document that has been implemented by the hospital. Please find the document attached for your reference.",
      time: "1 month ago",
    },
    {
      image: "",
      message:
        "Dear Doctor, We would like to inform you about a new referral criteria document that has been implemented by the hospital. Please find the document attached for your reference.",
      time: "1 month ago",
    },
    {
      image: "",
      message:
        "Dear Doctor, We would like to inform you about a new referral criteria document that has been implemented by the hospital. Please find the document attached for your reference.",
      time: "1 month ago",
    },
  ]);

  const notificationHandler = () => {
    console.log("working");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PaddingContainer>
          <Header title={"Notifications"} />
          <div className={classes["extraStyle"]}>
            {notificationsList?.length ? (
              notificationsList?.map((item, index) => (
                <NotificationComponent
                  data={item}
                  handler={notificationHandler}
                />
              ))
            ) : (
              <p className={classes["no-noti"]}>No Notifications Found</p>
            )}
          </div>
        </PaddingContainer>
      )}
    </>
  );
};

export default Notifications;
