import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { otpReducer } from "../store/authSlice";
import useApi from "./useApi";
import { loadingHandler } from "../store/loadingSlice";

const useSignupJoin = () => {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [steps, setSteps] = useState(0);
  const [communityCode, setCommunityCode] = useState("");
  const [user, setUser] = useState(null);
  const [seriesList, setSeriesList] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(loadingHandler(false));
    }, 2000);
  }, []);

  const form = useFormik({
    initialValues: {
      name: "",
      code: "+92",
      phoneNumber: "",
      cnic: "",
      email: "",
      password: "",
      confirmPassword: "",
      property: null,
      series: null,
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      phoneNumber: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      cnic: Yup.string().required("Required"),
      series: Yup.object().required("Required"),
      property: Yup.object().required("Required"),
      password: Yup.string()
        .required("Password is required")
        .min(5, "Your password is too short."),
      confirmPassword: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loadingHandler(true));
      api
        .generateOtp({
          code: values?.code,
          phoneNumber: values?.phoneNumber,
        })
        .then((res) => {
          if (res?.data?.headers.code === 200) {
            setUser(values);
            dispatch(otpReducer(res?.data?.body));
            setSteps(2);
            toast.success(res?.data?.headers?.msg);
          } else {
            toast.error(res?.data?.headers?.msg);
          }
        })
        .finally(() => {
          dispatch(loadingHandler(false));
        });
    },
  });

  const verifyCommunityCode = (e) => {
    e.preventDefault();
    dispatch(loadingHandler(true));
    api
      .verifyCommunity({
        code: communityCode,
      })
      .then((res) => {
        if (res?.data?.headers.code === 200) {
          const options = res.data.body?.dbAmmenitySeriesList?.map(
            (item, index) => {
              return {
                value: item?.communitySeriesDetailId,
                label: `${item?.ammenityName} ${item?.prefix}-${item?.unitName}`,
              };
            }
          );
          setSeriesList(options);
          setSteps(1);
        } else {
          toast.error(res?.data?.headers?.msg);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const verifyOtpHandler = (e) => {
    e.preventDefault();
    dispatch(loadingHandler(true));
    api
      .verifyOtp({
        otpCode: otp,
      })
      .then((res) => {
        if (res?.data?.headers.code === 200) {
          dispatch(otpReducer(res?.data?.body));
          setSteps(3);
        } else {
          toast.error(res?.data?.headers?.msg);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const signUpHandler = () => {
    dispatch(loadingHandler(true));
    api
      .signUpJoin({
        communityCode,
        name: user?.name,
        code: user?.code,
        email: user?.email,
        cnic: user?.cnic,
        phoneNumber: user?.phoneNumber,
        password: user?.password,
        propertyTypeId: user?.property.value,
        communitySeriesDetailId: user?.series.value,
      })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success(response?.data?.headers?.msg);
          cancelHandler();
          navigate("/");
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const cancelHandler = () => {
    setUser({
      name: "",
      code: "+92",
      phoneNumber: "",
      cnic: "",
      email: "",
      password: "",
      confirmPassword: "",
      property: "",
      series: "",
    });
    setOtp("");
    setError(false);
    // setSteps(0);
  };

  return {
    otp,
    setOtp,
    error,
    form,
    steps,
    setSteps,
    communityCode,
    setCommunityCode,
    seriesList,
    verifyOtpHandler,
    verifyCommunityCode,
    signUpHandler,
  };
};

export default useSignupJoin;
