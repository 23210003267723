import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { FaLock, FaLockOpen } from "react-icons/fa";
import CommonCard from "../../../../../components/CommonCard";
import MyButton from "../../../../../components/MyButton";
import MyInput from "../../../../../components/MyInput";
import MyPhoneCode from "../../../../../components/MyPhoneCode";
import MySearchSelect from "../../../../../components/MySearchSelect";
import styles from "./Step2.module.css";

const Step2 = ({ signup, seriesList, propertyTypeList, buttonHanler }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <CommonCard className={styles.mainWrapper}>
        <div className={styles.header}>
          <h1>Create an account</h1>
          <h2>
            Enter your account details below or{" "}
            <span onClick={buttonHanler}>login</span>
          </h2>
        </div>
        <Box
          component="form"
          onSubmit={signup.form.handleSubmit}
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          className={styles.formComponent}
          noValidate
          autoComplete="off"
        >
          <div style={{ marginTop: "5%" }}>
            <MyInput
              name="name"
              label="Name"
              value={signup.form.values.name}
              setter={signup?.form.handleChange("name")}
              error={signup.form.errors.name}
            />
            <Grid
              container
              columnGap={0}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid xs={2.5}>
                {/* <MyInput
                  name="code"
                  label="C-Code"
                  value={signup.form.values.code}
                  setter={signup?.form.handleChange("code")}
                  error={signup.form.errors.code}
                /> */}
                <MyPhoneCode
                  name="code"
                  label="C-Code"
                  value={signup.form.values.code}
                  setter={signup?.form.handleChange("code")}
                  error={signup.form.errors.code}
                />
              </Grid>
              <Grid xs={9}>
                <MyInput
                  name="phoneNumber"
                  label="Number"
                  value={signup.form.values.phoneNumber}
                  setter={signup?.form.handleChange("phoneNumber")}
                  error={signup.form.errors.phoneNumber}
                />
              </Grid>
            </Grid>
            <MyInput
              name="email"
              label="Email Address"
              value={signup.form.values.email}
              setter={signup?.form.handleChange("email")}
              error={signup.form.errors.email}
            />
            <MyInput
              name="password"
              label="Password"
              value={signup.form.values.password}
              setter={signup?.form.handleChange("password")}
              error={signup.form.errors.password}
              type={showPassword ? "text" : "password"}
              leftIcon={
                showPassword ? (
                  <FaLockOpen
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <FaLock
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              leftIconHandler={() => setShowPassword(!showPassword)}
            />
            <MyInput
              name="confirmPassword"
              label="Confirm Password"
              value={signup.form.values.confirmPassword}
              setter={signup?.form.handleChange("confirmPassword")}
              error={signup.form.errors.confirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              leftIcon={
                showConfirmPassword ? (
                  <FaLockOpen
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <FaLock
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              leftIconHandler={() =>
                setShowConfirmPassword(!showConfirmPassword)
              }
            />
            <MyInput
              name="cnic"
              label="CNIC"
              value={signup.form.values.cnic}
              setter={signup?.form.handleChange("cnic")}
              error={signup.form.errors.cnic}
            />
            <MySearchSelect
              label={"Type"}
              name="property"
              value={signup.form.values?.property}
              setter={(value) => {
                var setValue = propertyTypeList.find(
                  (option) => option.label === value.target.innerHTML
                );
                signup.form.setFieldValue("property", setValue);
              }}
              options={propertyTypeList}
              error={signup.form.errors.property}
            />
            <MySearchSelect
              label={"Property"}
              name="series"
              value={signup.form.values.series}
              setter={(value) => {
                var setValue = seriesList.find(
                  (option) => option.label === value.target.innerHTML
                );
                signup.form.setFieldValue("series", setValue);
              }}
              options={seriesList}
              error={signup.form.errors.series}
            />
          </div>
          <div className={styles.btnWrapper}>
            <MyButton
              btnText="Sign Up"
              type="submit"
              className={styles.extraBtnStyle}
            />
          </div>
        </Box>
      </CommonCard>
    </>
  );
};

export default Step2;
