import { Box, FormControlLabel, Radio, RadioGroup, Grid } from "@mui/material";
import React from "react";
import MyInput from "../MyInput";
import { RiDeleteBin6Line } from "react-icons/ri";
import AmenityAddComponent from "../AmmenityAddComponent";

const AmenitiesRightComponent = ({
  styles,
  amenitiesArr,
  handleChange,
  zoneArrHandler,
  handleAddSeries,
  handleRemoveSeries,
}) => {
  return (
    <>
      {amenitiesArr && (
        <Box
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={styles.formComponent}
        >
          {amenitiesArr.map((item, index) => (
            <AmenityAddComponent
              item={item}
              handleChange={handleChange}
              handleAddSeries={handleAddSeries}
              handleRemoveSeries={handleRemoveSeries}
              styles={styles}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default AmenitiesRightComponent;

// <div className={styles.formItem}>
//           <h1>How many floors do you have in {tab?.typeName}</h1>
//           <MyInput
//             name="noOfFloor"
//             label="Enter Name Here"
//             value={tab?.noOfFloor}
//             setter={handleChange}
//           />
//         </div>
//         <div className={styles.formItem}>
//           <h1>Are there multiple zone in each floor?</h1>
//           <RadioGroup
//             aria-labelledby="demo-radio-buttons-group-label"
//             // defaultValue={tab?.isZone}
//             value={tab?.isZone}
//             name="isZone"
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               gap: 5,
//               "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
//                 color: "var(--main-color-dark)",
//               },
//             }}
//             onChange={handleChange}
//           >
//             <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
//             <FormControlLabel value={"false"} control={<Radio />} label="No" />
//           </RadioGroup>
//         </div>
//         {tab?.isZone === "true" && (
//           <div className={styles.formItem}>
//             <h1>Define your zone list here</h1>

//             <div>
//               {tab?.zoneList?.map((item, index) => (
//                 <div className={styles.typeArrItem} key={index}>
//                   <MyInput
//                     name="zoneName"
//                     label="Enter Zone Name Here"
//                     value={item?.zoneName}
//                     setter={() => zoneArrHandler(item?.zoneID, item?.zoneName)}
//                   />
//                 </div>
//               ))}
//             </div>
//             <div>
//               <span
//                 className={styles.addNew}
//                 // onClick={() => filteredTypeArr?.isZone && addType()}
//               >
//                 Add New
//               </span>
//             </div>
//           </div>
//         )}
