import React, { useState } from "react";
import classes from "./Announcements.module.css";
import AnnouncementCard from "../../../../components/AnnouncementCard";
import FilterButton from "../../../../components/FilterButton";
import AddButton from "../../../../components/AddButton";
import DrawerWrapper from "../../../../components/DrawerWrapper";
import AddAnouncement from "./AddAnouncement";
import PreviewAnouncement from "./PreviewAnouncement";

const Announcements = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editUnable, setEditUnable] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [form, setForm] = useState({
    active: true,
    date: "",
    title: "",
    description: "",
    sendTo: "",
    validTill: "",
  });

  const announcementData = [
    {
      status: "Active",
      date: "12 Jan 2024",
      announcementTitle: "Pay Maintenance on time",
      announcementDescription:
        "Dear owners/Tenants,  Please pay maintenance as soon as possible because...",
    },
    {
      status: "Draft",
      date: "12 Jan 2024",
      announcementTitle: "Pay Maintenance on time",
      announcementDescription:
        "Dear owners/Tenants,  Please pay maintenance as soon as possible because...",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenAddModal(false);
    setEditUnable(false);
  };

  const handleChange = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDelete = () => {
    console.log("Announcement Deleted");
  };

  const handleOpenAdd = () => {
    setOpenAddModal(true);
    setEditUnable(false);
    setForm({
      active: true,
      date: "",
      title: "",
      description: "",
      sendTo: "",
      validTill: "",
    });
  };
  const handleEdit = (data) => {
    setOpenPreviewModal(false);
    setOpenAddModal(true);
    setEditUnable(true);
    setForm({
      active: true,
      date: data?.date,
      title: data?.announcementTitle,
      description: data?.announcementDescription,
      sendTo: "",
      validTill: "",
    });
  };

  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["headerWrapper"]}>
        <div className={classes["tabs-section"]}>
          <FilterButton text={"Active"} active={true} />
          <FilterButton text={"Current"} active={true} />
          <FilterButton text={"Recent"} />
          <FilterButton text={"Deleted"} />
          <FilterButton text={"Draft"} active={true} />
        </div>
        <AddButton handler={handleOpenAdd} />
      </div>
      <div className={classes["subSection"]}>
        <h1 className={classes["sub-heading"]}>Announcements</h1>
        {announcementData?.map((item, index) => (
          <AnnouncementCard
            data={item}
            handler={() => {
              setPreviewData(item);
              setOpenPreviewModal(true);
            }}
          />
        ))}
      </div>
      <DrawerWrapper
        headingText={`${
          editUnable ? "Edit Announcement" : "Create New Announcement"
        }`}
        open={openAddModal}
        setOpen={setOpenAddModal}
        btnText={"Save and Send"}
        extraBtn={"Draft"}
        handleSubmit={handleSubmit}
      >
        <AddAnouncement
          form={form}
          handleChange={handleChange}
          handleDelete={handleDelete}
        />
      </DrawerWrapper>
      <DrawerWrapper
        headingText="Preview Announcement"
        open={openPreviewModal}
        setOpen={setOpenPreviewModal}
        btnText={"Back"}
        handleSubmit={() => setOpenPreviewModal(false)}
      >
        <PreviewAnouncement
          data={previewData}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      </DrawerWrapper>
    </div>
  );
};

export default Announcements;
