import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { dataURLtoFile, fileDownloadFunction } from "../../helpers";
import useApi from "../../Hooks/useApi";
import UploadFile from "../../modals/UploadFile";
import UploadResponse from "../../modals/UploadResponse";
import { completeSetup } from "../../store/authSlice";
import styles from "./OnBoarding.module.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { loadingHandler } from "../../store/loadingSlice";

const OnBoarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.loadingReducer);

  const api = useApi();
  const { accessToken, communityName } = useSelector(
    (state) => state?.authReducer
  );
  const [steps, setSteps] = useState(1);
  const [communityCode, setCommunityCode] = useState("");
  const [error, setError] = useState(false);
  const [amenitiesArrList, setAmenitiesArrList] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [previewList, setPreviewList] = useState([]);

  const [form, setForm] = useState({
    communityName: communityName,
    communityAddress: "",
    communityAmmenityList: [],
  });

  const goBackHandler = () => {
    if (steps === 3) {
      setSteps(1);
    } else {
      setSteps((prev) => prev - 1);
    }
    setError(false);
  };

  const downloadHandler = async () => {
    dispatch(loadingHandler(true));
    api
      .DownloadAmmenitySeriesExcel()
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          const { fileContents, contentType, fileDownloadName } =
            response.data.body;
          const data = dataURLtoFile(fileContents, contentType);
          fileDownloadFunction(fileDownloadName, data, contentType);

          toast.success("File Downloaded Successfully.");
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const uploadHandler = async (formData) => {
    dispatch(loadingHandler(true));
    api
      .UploadAmmenitySeriesExcel({ formData })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("File uploaded Successfully.");
          handleCompleteManually();
        } else {
          setErrorMsg(response?.data?.headers?.msg);
          const { fileContents, contentType, fileDownloadName } =
            response.data.body;
          const data = dataURLtoFile(fileContents, contentType);
          fileDownloadFunction(fileDownloadName, data, contentType);
          setShowWarningModal(true);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
    setShowUploadModal(false);
  };

  const submitHandler = async () => {
    dispatch(loadingHandler(true));
    api
      .SaveAmmenityRanges({ form })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setPreviewList(response?.data?.body);
          toast.success(response?.data?.headers?.msg);
          setSteps(3);
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const getAllAmenities = async () => {
    dispatch(loadingHandler(true));
    api
      .GetAllAmmenities()
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setAmenitiesArrList(response?.data?.body);
        } else {
          toast.error(response?.data?.headers?.message);
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const handleCompleteManually = () => {
    dispatch(completeSetup());
  };

  const handleLayoutPreview = () => {
    const issues = form?.communityAmmenityList.some((item) => {
      if (item.ammenityID === 4 || item.ammenityID === 5) {
        return (
          item?.communityAmmenitySeriesList?.filter(
            (e) => !e?.name || e?.name.trim() === ""
          ).length > 0
        );
      } else {
        return (
          item?.communityAmmenitySeriesList?.filter(
            (e) =>
              !e?.prefix ||
              e?.prefix.trim() === "" ||
              !e?.seriesStart ||
              e?.seriesStart.trim() === "" ||
              !e?.seriesEnd ||
              e?.seriesEnd.trim() === ""
          ).length > 0
        );
      }
    });
    if (issues) {
      toast.error("Please fill all the fields");
      return;
    }
    setSteps(2);
  };

  useEffect(() => {
    getAllAmenities();
  }, []);

  const components = () => {
    if (steps === 1) {
      return (
        <Step1
          form={form}
          setForm={setForm}
          error={error}
          handleSubmit={handleLayoutPreview}
          amenitiesArrList={amenitiesArrList}
        />
      );
    } else if (steps === 2) {
      return (
        <Step2
          goBackHandler={goBackHandler}
          form={form}
          loading={loading}
          handleSubmit={submitHandler}
        />
      );
    } else if (steps === 3) {
      return (
        <Step3
          downloadHandler={downloadHandler}
          uploadHandler={() => setShowUploadModal(true)}
          manuallyHandler={handleCompleteManually}
          goBackHandler={goBackHandler}
          error={error}
          loading={loading}
        />
      );
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid lg={6} md={6} sm={12} xs={12} className={styles.wrapper}>
            {components()}
          </Grid>
          <UploadFile
            show={showUploadModal}
            setShow={() => setShowUploadModal(false)}
            handler={uploadHandler}
          />
          <UploadResponse
            show={showWarningModal}
            setShow={() => setShowWarningModal(false)}
            handler={handleCompleteManually}
            errorMsg={errorMsg}
          />
        </>
      )}
    </>
  );
};

export default OnBoarding;
