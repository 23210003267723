import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import { Box, Grid } from "@mui/material";
import SignUpInitialStep from "./SignUpInitialStep";
import SignUpCreate from "./SignupCreate";
import SignUpJoin from "./SignUpJoin";

const SignUp = () => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState(0);

  const components = () => {
    if (steps === 0) {
      return (
        <Grid container spacing={0} className={styles.parentWrapper}>
          <SignUpInitialStep
            createCommunityHandler={() => setSteps(1)}
            joinCommunityHandler={() => setSteps(2)}
            goBackHandler={() => navigate("/")}
          />
        </Grid>
      );
    } else if (steps === 1) {
      return (
        <Grid container spacing={0} className={styles.parentWrapper}>
          <SignUpCreate goBackHandler={() => setSteps(0)} />
        </Grid>
      );
    } else if (steps === 2) {
      return <SignUpJoin goBackHandler={() => setSteps(0)} />;
    }
  };

  return components();
};

export default SignUp;
