import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";

const MySearchSelect = ({
  name,
  value,
  label,
  setter,
  options,
  error,
  width = "100%",
}) => {
  return (
    <>
      <Autocomplete
        sx={{
          width: width,
          borderRadius: "12px",
        }}
        // defaultValue={value}
        value={value}
        onChange={setter}
        variant="outlined"
        size="large"
        className="custom-textfield"
        disablePortal
        options={options}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  );
};

export default MySearchSelect;
