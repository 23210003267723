import React, { useState } from "react";
import classes from "./Parking.module.css";
import SearchBox from "../../../components/SearchBox";
import MyButton from "../../../components/MyButton";
import { FaChevronRight } from "react-icons/fa";
import { RxCaretSort } from "react-icons/rx";
import TabComponent from "../../../components/TabComponent";
import ItemCard from "./ItemCard";
import ParkingAllotmentBSheet from "../MemberPage/ParkingAllotmentBSheet";
import ParkingAllotedBSheet from "../MemberPage/ParkingAllotedBSheet";
import Header from "../../../components/Header";
import PaddingContainer from "../../../components/PaddingContainer";

const Parking = () => {
  const [open, setOpen] = useState(false);
  const [allotedOpen, setAllotedOpen] = React.useState(false);
  const [allotedData, setAllotedData] = useState(null);
  const [form, setForm] = useState({
    parkingType: "",
    parkingName: "",
  });
  const [parkingData, setParkingData] = useState([
    {
      id: 1,
      name: "P1",
      parkingNo: [
        {
          no: "101",
          type: "member",
          name: "Haris Ali",
          address: "Flat 308, Block A",
          alloted: true,
        },
        { no: "102", alloted: false },
        { no: "103", alloted: false },
        { no: "104", alloted: false },
        { no: "105", alloted: false },
        { no: "106", alloted: false },
        { no: "107", alloted: false },
        { no: "108", alloted: false },
        { no: "109", alloted: false },
        { no: "110", alloted: false },
      ],
    },
    {
      id: 2,
      name: "P2",
      parkingNo: [
        {
          no: "201",
          type: "Staff",
          name: "Saad Ashraf",
          address: "Plot No: R-51, Block A",
          alloted: true,
        },
        { no: "202", alloted: false },
        { no: "203", alloted: false },
        { no: "204", alloted: false },
        { no: "205", alloted: false },
        { no: "206", alloted: false },
        { no: "207", alloted: false },
        { no: "208", alloted: false },
        { no: "209", alloted: false },
        { no: "210", alloted: false },
      ],
    },
  ]);

  const handleChange = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleAllotedChange = (val) => {
    setAllotedData((prev) => ({ ...prev, propertyType: val }));
  };

  const toggleDrawer = (data, key) => {
    if (key) {
      setAllotedData({
        type: data?.type,
        name: data?.name,
        flatNo: data?.no,
        address: data?.address,
      });
      setAllotedOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    setAllotedOpen(false);
  };

  return (
    <>
      <PaddingContainer>
        <Header title={"Parking"} />
        <div className={classes["searchWrapper"]}>
          <SearchBox />
        </div>
        <div className={classes["tabs-section"]}>
          <TabComponent text={"All Parkings"} isOpen={true} />
          <TabComponent text={"Available"} isOpen={false} />
        </div>
        <div className={classes["subSection"]}>
          <div className={classes["sub-heading"]}>
            <h1>Parking Lot</h1>
            {parkingData?.length > 1 && (
              <p>
                Sorting <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
              </p>
            )}
          </div>
          <ItemCard data={parkingData} handler={toggleDrawer} />
        </div>
      </PaddingContainer>
      <ParkingAllotmentBSheet
        open={open}
        setOpen={setOpen}
        form={form}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <ParkingAllotedBSheet
        open={allotedOpen}
        setOpen={setAllotedOpen}
        form={allotedData}
        handleChange={handleAllotedChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Parking;
