import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import FilterButton from "../../../../components/FilterButton";
import DashboardCard from "../../../../components/DashboardCard";
import { FaCircleArrowLeft } from "react-icons/fa6";
import classes from "./MemberDuesPage.module.css";
import MyButton from "../../../../components/MyButton";
import { FaChevronRight } from "react-icons/fa";

const MemberDuesPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className={classes["mainWrapper"]}>
      <div style={{ margin: "0px" }} className="c-p">
        <FaCircleArrowLeft
          color={"var(--main-color)"}
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={classes["orangeCardsWrapper"]}>
        <DashboardCard count={"1000"} text={"Total Property Dues"} />
        <DashboardCard count={"1000"} text={"Total Parking Dues"} />
      </div>
      <div className={classes["tabs-section"]}>
        <FilterButton text={"Pending Dues"} active={true} />
        <FilterButton text={"Advance Receives"} />
        <FilterButton text={"Date"} />
        <FilterButton text={"Tenants"} />
        <FilterButton text={"Owner"} />
      </div>
      <div className={classes["subSection"]}>
        <div className={classes["members-heading"]}>
          <div className={classes["title"]}>
            <p>Flat/{id} - Block A - 1st Floor</p>
            <h1>Haris Ali</h1>
          </div>
          <p>02 Records</p>
        </div>
      </div>
      <Card
        name={"Parking"}
        dues={"5000"}
        date={"12 Jan 2024"}
        handleClick={() => {}}
      />
      <Card
        name={"Property Maintenance"}
        dues={"5000"}
        date={"12 Jan 2024"}
        handleClick={() => {}}
      />
    </div>
  );
};

export default MemberDuesPage;

const Card = ({ name, dues, date, handleClick }) => {
  return (
    <div className={classes["cardMainWrapper"]} onClick={handleClick}>
      <div className={classes["userDetailsWrapper"]}>
        <div className={classes["detailsWrapper"]}>
          <div className={classes["userDetailsDiv"]}>
            <p>{date}</p>
            <p>{name}</p>
          </div>
          <div className={classes["buttonDiv"]}>
            <p>
              <span>{dues}</span> total dues
            </p>
          </div>
        </div>
        <FaChevronRight size={20} />
      </div>
      <MyButton btnText={"Collect"} className={classes["extraBtnStyles"]} />
    </div>
  );
};
