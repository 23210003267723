import React from "react";
import classes from "./MainDashboard.module.css";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { GoSearch } from "react-icons/go";
import { BsChevronDown } from "react-icons/bs";
import MySelect from "../../../../components/MySelect";
import TabComponent from "../../../../components/TabComponent";
import DashboardCard from "../../../../components/DashboardCard";
import ComplainCard from "../../../../components/ComplainCard";
import AnnouncementCard from "../../../../components/AnnouncementCard";
import RecommendationsCard from "../../../../components/RecommendationsCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PaddingContainer from "../../../../components/PaddingContainer";

const MainDashboard = ({
  setCommunityHandler,
  complainData,
  announcementData,
  filter,
}) => {
  const navigate = useNavigate();
  const { communityName } = useSelector((state) => state?.authReducer);
  return (
    <PaddingContainer>
      <h1 className={classes["username-heading"]}>Hi, Saad Ashraf</h1>
      <div className={classes["community-dropdown"]}>
        <MySelect
          name="communityName"
          label="Community Name"
          value={communityName}
          disabled={true}
          // setter={(val) => setForm((prev) => ({ ...prev, roleName: val }))}
          options={[{ value: communityName, label: communityName }]}
        />
        <div className={[classes.addMenuBtn, "c-p"].join(" ")}>
          <HiOutlineViewGridAdd color="var(--secondary-color)" size={35} />
        </div>
      </div>
      <div className={classes["tabs-section"]}>
        <div className={[classes.addMenuBtn, "c-p"].join(" ")}>
          <GoSearch color="var(--secondary-color)" size={25} />
        </div>
        <TabComponent text={"Blocks"} />
        <TabComponent text={"Floors"} />
        <TabComponent
          text={"Flats"}
          onClick={() => navigate("/amenitiesPage")}
        />
      </div>
      <div className={classes["filterComponent"]}>
        <p>This {filter?.label}</p>
        <BsChevronDown
          color={"var(--main-color)"}
          size={15}
          style={{ strokeWidth: "2px" }}
        />
      </div>
      <div className={classes["orangeCardsWrapper"]}>
        <DashboardCard count={"1000"} text={"Cash Received"} />
        <DashboardCard count={"1000"} text={"Total Dues"} />
        <DashboardCard count={"1000"} text={"Total Expense"} />
        <DashboardCard count={"1000"} text={"Approvals"} />
      </div>
      <div className={classes["subSection"]}>
        <h1 className={classes["sub-heading"]}>Complain Center</h1>
        <ComplainCard data={complainData} />
      </div>
      <div className={classes["subSection"]}>
        <h1 className={classes["sub-heading"]}>Announcements</h1>
        <AnnouncementCard data={announcementData} />
      </div>
      <div className={classes["subSection"]}>
        <h1 className={classes["sub-heading"]}>Recommendations</h1>
        <RecommendationsCard data={announcementData} />
      </div>
    </PaddingContainer>
  );
};

export default MainDashboard;
