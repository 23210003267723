import React from "react";
import classes from "./AddUnionMember.module.css";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../../components/MyInput";

const AddUnionMember = ({ form, handleChange }) => {
  return (
    <div className={classes["formCardMainWrapper"]}>
      <Box
        component="form"
        // onSubmit={handleSubmit}
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MyInput
          name="name"
          label="Name"
          value={form?.name}
          setter={(e) => handleChange(e)}
        />
        <Grid container columnGap={1}>
          <Grid xs={2.8}>
            <MyInput
              name="code"
              label="C-Code"
              value={form?.code}
              setter={(e) => handleChange(e)}
            />
          </Grid>
          <Grid xs={9}>
            <MyInput
              name="contact"
              label="Number"
              value={form?.contact}
              setter={(e) => handleChange(e)}
            />
          </Grid>
        </Grid>
        <MyInput
          name="nic"
          label="NIC Number"
          value={form?.nic}
          setter={(e) => handleChange(e)}
        />
        <MyInput
          name="role"
          label="Role"
          value={form?.role}
          setter={(e) => handleChange(e)}
        />
        <MyInput
          name="address"
          label="Address"
          value={form?.address}
          setter={(e) => handleChange(e)}
        />
      </Box>
    </div>
  );
};

export default AddUnionMember;
