import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./SignUpCreate.module.css";
import AuthMainComponent from "../../../../components/AuthMainComponent";
import AuthSubComponent from "../../../../components/AuthSubComponent";
import MyInput from "../../../../components/MyInput";
import MyButton from "../../../../components/MyButton";
import MyCheckBox from "../../../../components/MyCheckBox";
import { useNavigate } from "react-router-dom";
import SignupStep1 from "./Step1";
import useSignup from "../../../../Hooks/useSignup";
import Step1 from "./Step1";
import Step3 from "./Step3";
import OTPScreen from "../OTPScreen";
import { loadingHandler } from "../../../../store/loadingSlice";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";

export default function SignUpCreate() {
  const navigate = useNavigate();
  const signup = useSignup();
  const [error, setError] = useState(false);
  const { loading } = useSelector((state) => state?.loadingReducer);

  const buttonHanler = () => {
    navigate("/");
  };

  const components = () => {
    if (signup.steps === 2) {
      return (
        <Grid container spacing={0} className={styles.parentWrapper}>
          <Step3
            form={signup.user}
            loading={loading}
            handleChange={signup.handleChange}
            handleSubmit={signup.signUpHandler}
            goBackHandler={buttonHanler}
          />
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          spacing={0}
          className={signup.steps === 1 && styles.parentWrapper}
        >
          <AuthMainComponent className={styles.otpStyles}>
            {signup.steps === 0 ? (
              <Step1 buttonHanler={buttonHanler} signup={signup} />
            ) : (
              <OTPScreen
                otp={signup?.otp}
                handleChange={(val) => signup.setOtp(val)}
                handleSubmit={signup.verifyOtpHandler}
                data={signup.form.values}
                error={error}
              />
            )}
          </AuthMainComponent>
          <AuthSubComponent
            text={"Already have an account please? Login"}
            btnText={"Login"}
            btnFunc={buttonHanler}
          />
        </Grid>
      );
    }
  };

  return <>{loading ? <Loader /> : components()}</>;
}
