import React, { useState, useEffect } from "react";
import classes from "./MemberDetailsPage.module.css";
import DashboardCard from "../../../../components/DashboardCard";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { HiPlusSm } from "react-icons/hi";
import { GoDash } from "react-icons/go";
import MyInput from "../../../../components/MyInput";
import { Box, Grid } from "@mui/material";
import MyButton from "../../../../components/MyButton";
import { FaChevronRight } from "react-icons/fa";
import MemberSettingsBSheet from "../MemberSettingsBSheet";
import MyPhoneCode from "../../../../components/MyPhoneCode";
import useUser from "../../../../Hooks/useUser";
import MySelect from "../../../../components/MySelect";
import { amenityNameFormatter } from "../../../../helpers";
import UserDetailsForm from "../../../../components/UserDetailsForm";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import Loader from "../../../../components/Loader";
import { useSelector } from "react-redux";

const MemberDetailsPage = () => {
  const { loading } = useSelector((state) => state?.loadingReducer);

  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const generalApis = useGeneralApi();

  // const [propertySettingForm, setPropertySettingForm] = useState({
  //   propertyName: id,
  //   maintenanceFee: "",
  // });
  // const [parkingSettingForm, setParkingSettingForm] = useState({
  //   parkingName: "",
  //   parkingFee: "",
  // });

  // const handlePropertySettingsChange = (e) => {
  //   const { name, value } = e.target;
  //   setPropertySetingForm((prev) => ({ ...prev, [name]: value }));
  // };
  // const handleParkingSettingsChange = (e) => {
  //   const { name, value } = e.target;
  //   setParkingSettingForm((prev) => ({ ...prev, [name]: value }));
  // };

  const recentHistoryHandler = () => {
    console.log("Recent History Button Clicked !");
  };

  const [open, setOpen] = useState(false);

  // const toggleDrawer = (newOpen) => () => {
  //   setOpen(newOpen);
  // };

  const duesHanlder = () => {
    navigate(`/member/${id}/dues`);
  };

  useEffect(() => {
    user?.getAmmenityDetailBySeriesDetailId(id);
  }, [id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes["mainWrapper"]}>
          <div style={{ margin: "0px" }} className="c-p">
            <FaCircleArrowLeft
              color={"var(--main-color)"}
              size={40}
              onClick={() => navigate(-1)}
            />
          </div>
          <div className={classes["orangeCardsWrapper"]}>
            <DashboardCard
              count={"1000"}
              text={"Total Dues"}
              // handler={duesHanlder}
            />
            <DashboardCard count={"1000"} text={"Cash Received"} />
            <DashboardCard count={"1000"} text={"Total Expense"} />
            <DashboardCard count={"1000"} text={"Approvals"} />
          </div>
          {state?.position && (
            <Card
              position={state?.position}
              handleClick={() => setOpen(true)}
            />
          )}
          <Card1
            name={user?.amenityDetails}
            handleClick={() => setOpen(true)}
          />
          <Card2 handleClick={() => setOpen(true)} />
          <UserDetailsForm
            form={user?.ownerForm}
            name="Property Owner"
            roleList={generalApis.roleList}
            disabled={user?.formsList.some((item) => item === "owner")}
          />
          <UserDetailsForm
            form={user?.tenantForm}
            name="Property Tenant"
            roleList={generalApis.roleList}
            disabled={user?.formsList.some((item) => item === "tenant")}
          />
          {/* <MyButton
          className={classes["extraBtnStyle"]}
          handler={recentHistoryHandler}
        >
          <span className={classes["btnHeading"]}>Recent History</span>
          <FaChevronRight />
        </MyButton> */}
        </div>
      )}
    </>
  );
};

const Card = ({ position, handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Role</p>
            <p>{position}</p>
          </div>
        </div>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
    </div>
  );
};

const Card1 = ({ name, handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Address</p>
            <p>
              {amenityNameFormatter(
                name?.ammenityName,
                name?.prefix,
                name?.unitName
              )}
            </p>
          </div>
          <div className={classes["buttonDiv"]}>
            <p>Set Monthly Fee</p>
          </div>
        </div>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
    </div>
  );
};

const Card2 = ({ handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Parking allot</p>
          </div>
          <div className={classes["buttonDiv"]}>
            <p>Set Monthly Fee</p>
          </div>
        </div>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
    </div>
  );
};

// const Card4 = ({ form }) => {
//   return (
//     <div className={classes["formCardMainWrapper"]}>
//       <div className={classes["header"]}>
//         <p>Property Tenant</p>
//         {/* <p style={{ color: "var(--blue-color)" }}>
//           <GoDash size={20} style={{ strokeWidth: "1px" }} />
//           Owner
//         </p> */}
//         {/* <p style={{ color: "var(--green-color)" }}>
//           <GoDash size={20} style={{ strokeWidth: "1px" }} />
//           Tenant
//         </p> */}
//         <HiPlusSm size={30} color="var(--black-color)" />
//       </div>
//       <Box
//         component="form"
//         // onSubmit={handleSubmit}
//         sx={{
//           "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
//         }}
//         className={classes["formWrapper"]}
//         noValidate
//         autoComplete="off"
//       >
//         <MyInput
//           name="name"
//           label="Name"
//           value={form?.values?.name}
//           setter={form.handleChange("name")}
//           error={form.errors.name}
//         />
//         <Grid container columnGap={1}>
//           <Grid xs={2.8}>
//             <MyPhoneCode
//               name="countryCode"
//               label="C-Code"
//               value={form?.values?.countryCode}
//               setter={form.handleChange("countryCode")}
//               error={form.errors.countryCode}
//             />
//           </Grid>
//           <Grid xs={9}>
//             <MyInput
//               name="cellNo"
//               label="Number"
//               value={form?.values?.cellNo}
//               setter={form.handleChange("cellNo")}
//               error={form.errors.cellNo}
//             />
//           </Grid>
//         </Grid>
//         <MyInput
//           name="emailAddress"
//           label="Email Address"
//           value={form?.values?.emailAddress}
//           setter={form.handleChange("emailAddress")}
//           error={form.errors.emailAddress}
//         />
//         <MyInput
//           name="cnic"
//           label="CNIC Number"
//           value={form?.values?.cnic}
//           setter={form.handleChange("cnic")}
//           error={form.errors.cnic}
//         />
//         <MyInput
//           name="address"
//           label="Address"
//           value={form?.values?.emergencyPerson}
//           setter={form.handleChange("emergencyPerson")}
//           error={form.errors.emergencyPerson}
//         />
//         <Grid container columnGap={1}>
//           <Grid xs={2.8}>
//             <MyPhoneCode
//               name="emergencyContactCountryCode"
//               label="Code"
//               value={form?.values?.emergencyContactCountryCode}
//               setter={form.handleChange("emergencyContactCountryCode")}
//               error={form.errors.emergencyContactCountryCode}
//             />
//           </Grid>
//           <Grid xs={9}>
//             <MyInput
//               name="emergencyContactCellNo"
//               label="Emergency Number"
//               value={form?.values?.emergencyContactCellNo}
//               setter={form.handleChange("emergencyContactCellNo")}
//               error={form.errors.emergencyContactCellNo}
//             />
//           </Grid>
//         </Grid>
//         <MyInput
//           name="emergencyContactPersonName"
//           label="Emergency Person Name"
//           value={form?.values?.emergencyContactPersonName}
//           setter={form.handleChange("emergencyContactPersonName")}
//           error={form.errors.emergencyContactPersonName}
//         />
//       </Box>
//     </div>
//   );
// };

export default MemberDetailsPage;

{
  /* <MemberSettingsBSheet
        open={open}
        setOpen={setOpen}
        propertySettingForm={propertySettingForm}
        setPropertySettingForm={setPropertySettingForm}
        parkingSettingForm={parkingSettingForm}
        setParkingSettingForm={setParkingSettingForm}
        handlePropertySettingsChange={handlePropertySettingsChange}
        handleParkingSettingsChange={handleParkingSettingsChange}
        handleSubmit={() => {}}
      /> */
}
