import { Box } from "@mui/material";
import React from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import CommonCard from "../../../../../components/CommonCard";
import MyButton from "../../../../../components/MyButton";
import MyInput from "../../../../../components/MyInput";
import styles from "./Step3.module.css";

const Step3 = ({
  handleSubmit,
  form,
  handleChange,
  loading,
  goBackHandler,
}) => {
  return (
    <CommonCard className={[styles.mainWrapper, styles.extraStyles].join(" ")}>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          gap: "10px",
          margin: "30px 0px",
        }}
        className={styles.formComponent}
      >
        <div style={{ margin: "20px 0px" }} className="c-p">
          <FaCircleArrowLeft
            color={"var(--main-color)"}
            size={35}
            onClick={goBackHandler}
          />
        </div>
        <div className={styles.formItem}>
          <h1>Enter your community name</h1>
          <MyInput
            name="communityName"
            label="Enter Name Here"
            value={form?.communityName}
            setter={handleChange}
          />
        </div>
        <div className={styles.formItem}>
          <h1>Enter complete community address</h1>
          <MyInput
            name="communityAddress"
            label="Enter Address Here"
            value={form?.communityAddress}
            setter={handleChange}
          />
        </div>
      </Box>
      <div className={styles.btnWrapper}>
        <MyButton
          btnText="Continue"
          className={styles.extraBtnStyle}
          disabled={loading}
          handler={handleSubmit}
        />
      </div>
    </CommonCard>
  );
};

export default Step3;
