import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { otpReducer } from "../store/authSlice";
import { toast } from "react-toastify";
import { loadingHandler } from "../store/loadingSlice";

const useApprovals = () => {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [approvalStatus, setApprovalStatus] = useState(1);
  const [approvalsList, setApprovalsList] = useState([]);
  const [singleApproval, setSingleApproval] = useState({
    approvalRequestId: 0,
    name: "",
    cellNo: "",
    countryCode: "",
    address: "",
    cnic: "",
    emergencyContact: 0,
    emergencyPersonName: "",
    propertyTypeId: null,
    remarks: "",
    requestedDate: "",
    communitySeriesDetailId: 0,
    role: null,
  });

  const approvalPreviewForm = useFormik({
    initialValues: singleApproval,
    enableReinitialize: true,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      cellNo: Yup.number().required("Required"),
      countryCode: Yup.string().required("Required"),
      propertyTypeId: Yup.string().required("Required"),
      role: Yup.string().required("Required"),
      //   emergencyContact: Yup.number(),
      //   emergencyPersonName: Yup.string(),
      //   cnic: Yup.string(),
      //   address: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loadingHandler(true));
      const data = {
        propertyTypeId: values?.propertyTypeId,
        communitySeriesDetailId: values?.communitySeriesDetailId,
        approvalRequestId: singleApproval?.approvalRequestId,
        approvalStatusId: approvalStatus,
        name: values.name,
        address: values.address,
        cnic: values.cnic,
        emergencyContactPersonName: values.emergencyContactPersonName,
        emergencyContactCellNo: values.emergencyContactCellNo,
        roleId: values.role,
      };
      api
        .handleApprovalRequest({
          data,
        })
        .then((response) => {
          if (response?.data?.headers?.code === 200) {
            toast.success(response?.data?.headers?.msg);
          } else {
            toast.error(response?.data?.headers?.msg);
          }
        })
        .finally(() => {
          dispatch(loadingHandler(false));
        });
      window.location.reload();
    },
  });

  function getAllApprovals() {
    dispatch(loadingHandler(true));
    api
      ?.GetAllApprovalsListing()
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setApprovalsList(response?.data?.body);
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  }

  function getApprovalById(id) {
    dispatch(loadingHandler(true));
    api
      ?.GetApprovalById(id)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          const item = response?.data?.body[0];
          setSingleApproval({
            ...item,
            approvalRequestId: id,
            propertyTypeId: item?.propertyTypeId,
            cellNo: item?.cellNo?.slice(3, 13),
            countryCode: item?.cellNo?.slice(0, 3),
          });
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  }

  return {
    getAllApprovals,
    getApprovalById,
    setApprovalStatus,
    approvalsList,
    singleApproval,
    approvalPreviewForm,
  };
};

export default useApprovals;
