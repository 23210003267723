import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MySelect = ({
  name,
  value,
  label,
  setter,
  options,
  error,
  disabled = false,
  width = "100%",
}) => {
  return (
    <>
      <Box sx={{ minWidth: 120, width: width }}>
        <FormControl fullWidth error={!!error}>
          <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            value={value}
            label={label}
            onChange={(e) => setter(e.target.value)}
            // disabled={disabled}
            readOnly={disabled}
          >
            {options?.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <span
        style={{
          display: "inline-block",
          color: "red",
          fontSize: "14px",
          marginBottom: "5px",
        }}
      >
        {error}
      </span>
    </>
  );
};

export default MySelect;
