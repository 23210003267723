import React, { useEffect, useState } from "react";
import classes from "./MemberPage.module.css";
import { FaCircleArrowLeft } from "react-icons/fa6";
import SearchBox from "../../../components/SearchBox";
import MySelect from "../../../components/MySelect";
import { RxCaretSort } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import TabComponent from "../../../components/TabComponent";
import FilterButton from "../../../components/FilterButton";
import AmenitiesItemCard from "../Dashboard/AmenitiesDashboard/AmenitiesItemCard";
import MemberItemCard from "./MemberItemCard";
import useMember from "../../../Hooks/useMember";
import useGeneralApi from "../../../Hooks/useGeneralApi";
import Header from "../../../components/Header";
import PaddingContainer from "../../../components/PaddingContainer";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import { loadingHandler } from "../../../store/loadingSlice";

const MemberPage = () => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.loadingReducer);

  const { filteredData, filteredDataHandler, memberList } = useMember();
  const generalApis = useGeneralApi();

  const memberHandler = (id) => {
    console.log(id);
    // navigate(`/member/${id}`);
  };

  useEffect(() => {
    if (generalApis.seriesList.length > 0) {
      filteredDataHandler(generalApis?.seriesList);
    }
  }, [generalApis?.seriesList]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PaddingContainer>
          {/* <div style={{ margin: "0px" }} className="c-p">
        <FaCircleArrowLeft
          color={"var(--main-color)"}
          size={40}
          onClick={() => navigate(-1)}
        />
      </div> */}
          <Header title={"Members"} />
          <div className={classes["searchWrapper"]}>
            <SearchBox label={"Search"} name={"search"} />
          </div>
          <div className={classes["tabs-section"]}>
            <FilterButton text={"Owner"} active={true} />
            <FilterButton text={"Tenants"} />
            <FilterButton text={"Properties"} />
            <FilterButton text={"Contact"} active={true} />
          </div>
          <div className={classes["subSection"]}>
            <div className={classes["members-heading"]}>
              <h1>Members List</h1>
              <p>
                {memberList?.length < 9
                  ? `0${memberList?.length}`
                  : memberList?.length}{" "}
                Records
              </p>
            </div>
            {memberList?.map((item, index) => (
              <MemberItemCard item={item} key={index} onClick={memberHandler} />
            ))}
          </div>
          {Object.keys(filteredData).length > 0 &&
            filteredData?.map((item, index) => (
              <div className={classes["subSection"]}>
                <div className={classes["sub-heading"]}>
                  <h1>{item[0]}</h1>
                  {item[1]?.length > 1 && (
                    <p>
                      Sorting{" "}
                      <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
                    </p>
                  )}
                </div>
                <AmenitiesItemCard data={item[1]} />
              </div>
            ))}
        </PaddingContainer>
      )}
    </>
  );
};

export default MemberPage;
