import moment from "moment";
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export var recordsLimit = 10;

export const dateDifference = (date) => {
  let duration = moment.duration(moment().diff(date));
  let daysDiff = duration.asDays();
  return daysDiff;
};

export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const hidePhoneNumber = (phoneNumber) => {
  const numDigitsToHide = phoneNumber?.length - 5;

  const hiddenPart = "*".repeat(numDigitsToHide);
  const firstPart = phoneNumber.slice(0, 1);
  const lastPart = phoneNumber.slice(-4);

  const hiddenPhoneNumber = `${firstPart}${hiddenPart}${lastPart}`;

  return hiddenPhoneNumber;
};

export const fileDownloadFunction = (name, data, type) => {
  const blob = new Blob([data], { type });
  const fileUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function dataURLtoFile(data, type) {
  const binaryData = atob(data);
  const byteNumbers = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteNumbers[i] = binaryData.charCodeAt(i);
  }
  return byteNumbers;
}

export function amenityNameFormatter(ammenityName, prefix, unitName) {
  const displayName = prefix ? ` ${prefix}-${unitName}` : ` - ${unitName}`;
  return ammenityName + displayName;
}
