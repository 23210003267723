import React, { useState } from "react";
import classes from "./LayoutPreviewCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import { MdChevronLeft } from "react-icons/md";

const LayoutPreviewCard = ({ data }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  return (
    <div className={classes["mainWrapper"]}>
      <MdChevronLeft
        size={100}
        color={`${page > 0 ? "var(--main-color-dark)" : "var(--hr-color)"}`}
        onClick={() => page > 0 && setPage((prev) => prev - 1)}
      />
      <div className={classes["previewCard"]}>
        <div className={classes["header"]}>
          <p>
            {data?.ammenityID !== 4 && data?.ammenityID !== 5
              ? data?.ammenityName +
                "-" +
                data?.communityAmmenitySeriesList[page]?.prefix
              : data?.ammenityName}
          </p>
        </div>
        <div className={classes["layout"]}>
          {data?.ammenityID !== 4 && data?.ammenityID !== 5 ? (
            <BoxesCard item={data?.communityAmmenitySeriesList[page]} />
          ) : (
            <BoxesCard1 item={data?.communityAmmenitySeriesList[page]} />
          )}
        </div>
      </div>
      <MdChevronRight
        size={100}
        color={`${
          data?.communityAmmenitySeriesList.length > page + 1
            ? "var(--main-color-dark)"
            : "var(--hr-color)"
        }`}
        onClick={() =>
          data?.communityAmmenitySeriesList.length > page + 1 &&
          setPage((prev) => prev + 1)
        }
      />
    </div>
  );
};
export default LayoutPreviewCard;

const BoxesCard = ({ item }) => {
  const numbers = [];
  for (let i = item?.seriesStart; i <= item?.seriesEnd; i++) {
    numbers.push(i);
  }
  return (
    <>
      {numbers?.map((ele, idx) => (
        <div className={classes["flatItem"]}>{`${item?.prefix}-${ele}`}</div>
      ))}
    </>
  );
};

const BoxesCard1 = ({ item }) => {
  return (
    <>
      <div className={classes["flatItem"]}>{item?.name}</div>
    </>
  );
};
