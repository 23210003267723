import React, { useState } from "react";
import classes from "./MainSettings.module.css";
import { BsChevronRight } from "react-icons/bs";
import TabComponent from "../../../../components/TabComponent";
import DrawerWrapper from "../../../../components/DrawerWrapper";
import ProfileSettings from "./ProfileSettings";
import PasswordSettings from "./PasswordSettings";

const MainSettings = () => {
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [profileForm, setProfileForm] = useState({
    name: "",
    code: "",
    phoneNumber: "",
    email: "",
  });
  const [passwwordForm, setPasswwordForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleProfileSettingChange = (e) => {
    const { name, value } = e.target;
    setProfileForm((prev) => ({ ...prev, [name]: value }));
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswwordForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className={classes["headerWrapper"]}>
        <div className={classes["tabs-section"]}>
          <TabComponent text={"Home"} />
          <TabComponent text={"Notofications"} />
          <TabComponent text={"Complains"} />
          <TabComponent text={"Announcements"} />
        </div>
      </div>
      <div className={classes["generalSection"]}>
        <p>General Settings</p>
        <Card
          heading={"Change Password"}
          handler={() => setOpenPasswordModal(true)}
        />
        <Card
          heading={"Profile Settings"}
          handler={() => setOpenProfileModal(true)}
        />
      </div>
      <div className={classes["generalSection"]}>
        <p>Advance Settings</p>
        <Card heading={"Ledger Settings"} />
        <Card heading={"Approvals"} />
      </div>
      <div className={classes["generalSection"]}>
        <p>Terms and Policies</p>
        <Card1 heading={"Terms and Conditions"} />
        <Card1 heading={"Privacy Policies"} />
      </div>
      <DrawerWrapper
        headingText={"Profile Settings"}
        open={openProfileModal}
        setOpen={setOpenProfileModal}
        btnText={"Save Changes"}
        // handleSubmit={handleSubmit}
      >
        <ProfileSettings
          form={profileForm}
          handleChange={handleProfileSettingChange}
        />
      </DrawerWrapper>
      <DrawerWrapper
        headingText={"Change Password"}
        open={openPasswordModal}
        setOpen={setOpenPasswordModal}
        btnText={"Save Changes"}
        // handleSubmit={handleSubmit}
      >
        <PasswordSettings
          form={passwwordForm}
          handleChange={handlePasswordChange}
        />
      </DrawerWrapper>
    </>
  );
};

export default MainSettings;

const Card = ({ handler, heading }) => {
  return (
    <div className={classes["cardWrapper"]} onClick={handler}>
      <h1>{heading}</h1>
      <BsChevronRight
        style={{
          float: "right",
          strokeWidth: "2px",
          color: "var(--main-color)",
        }}
      />
    </div>
  );
};

const Card1 = ({ handler, heading }) => {
  return (
    <div className={classes["card1Wrapper"]} onClick={handler}>
      <h1>{heading}</h1>
      <BsChevronRight
        style={{
          float: "right",
          strokeWidth: "2px",
          color: "var(--main-color)",
        }}
      />
    </div>
  );
};
