import React, { useState, useEffect } from "react";
import useApi from "./useApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loadingHandler } from "../store/loadingSlice";

const useGeneralApi = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);

  const approvalStatus = [
    {
      id: 1,
      label: "Pending",
    },
    {
      id: 2,
      label: "Approved",
    },
    {
      id: 3,
      label: "Reject",
    },
    {
      id: 4,
      label: "No needs for approval",
    },
  ];

  useEffect(() => {
    settledAllFunction();
  }, []);

  const settledAllFunction = async () => {
    dispatch(loadingHandler(true));
    await Promise.allSettled([
      getAllRole(),
      GetAllSeriesByCommunity(),
      getAllPropertiesType(),
    ])
      .then()
      .catch((err) => {
        toast.error("Something Went Wrong");
      })
      .finally(() => {
        // dispatch(loadingHandler(false));
      });
  };

  function getAllRole() {
    api?.GetAllRole().then((response) => {
      if (response?.data?.headers?.code === 200) {
        setRoleList(response?.data?.body);
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  function GetAllSeriesByCommunity() {
    api?.getAllSeriesByCommunity().then((response) => {
      if (response?.data?.headers?.code === 200) {
        setSeriesList(response?.data?.body);
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  function getAllPropertiesType() {
    api.GetAllPropertyType().then((response) => {
      if (response?.data?.headers?.code === 200) {
        const options = response?.data?.body.map((item, index) => {
          return {
            value: item?.propertyTypeId,
            label: item?.name,
          };
        });
        setPropertyTypeList(options);
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  return { roleList, seriesList, propertyTypeList, approvalStatus };
};

export default useGeneralApi;
