import React from "react";
import AmenitiesItem from "../AmenitiesItem";

const AmenitiesComponent = ({
  handler,
  amenitiesArrList,
  amenitiesArr,
  styles,
}) => {
  return (
    <>
      <div className={styles.amenitiesWrapper}>
        {amenitiesArrList?.map((item, index) => (
          <AmenitiesItem
            data={item}
            selected={
              amenitiesArr?.filter(
                (ele) => ele?.ammenityID === item?.ammenityID
              ).length > 0
            }
            handler={handler}
          />
        ))}
      </div>
    </>
  );
};

export default AmenitiesComponent;
