import React from "react";
import classes from "./Header.module.css";

const Header = ({ title }) => {
  return (
    <div className={classes["headingWrapper"]}>
      <h1 className={classes["heading"]}>{title}</h1>
    </div>
  );
};

export default Header;
