import React from "react";
import classes from "./AnnouncementCard.module.css";

const AnnouncementCard = ({ data, handler }) => {
  const { status, date, announcementTitle, announcementDescription } = data;
  return (
    <div className={classes["mainWrapper"]} onClick={handler}>
      <div className={classes["firstDiv"]}>
        <p
          style={{
            color:
              status === "Draft" ? "var(--orange-color)" : "var(--main-color)",
          }}
        >
          {status}
        </p>
        <p>{date}</p>
      </div>
      <div className={classes["announcementDetailsDiv"]}>
        <p>{announcementTitle}</p>
        <p>{announcementDescription}</p>
      </div>
    </div>
  );
};

export default AnnouncementCard;
