import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { community, login } from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";

const useUser = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [amenityDetails, setAmenityDetails] = useState({});
  const [communitySeriesDetailId, setCommunitySeriesDetailId] = useState(0);
  const [formsList, setFormsList] = useState([]);
  const [ownerData, setOwnerData] = useState({
    userDetailID: 0,
    name: "",
    countryCode: "+92",
    cellNo: "",
    cnic: "",
    emergencyContactCellNo: "",
    emergencyContactPersonName: "",
    address: "",
    role: null,
    communitySeriesDetailId: 0,
    propertyTypeId: 1,
  });
  const [tenantData, setTenantData] = useState({
    userDetailID: 0,
    name: "",
    countryCode: "+92",
    cellNo: "",
    cnic: "",
    emergencyContactCellNo: "",
    emergencyContactPersonName: "",
    address: "",
    role: null,
    communitySeriesDetailId: 0,
    propertyTypeId: 2,
  });

  const ownerForm = useFormik({
    initialValues: ownerData,
    enableReinitialize: true,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      // countryCode: Yup.string().required("Required"),
      cellNo: Yup.string().required("Required"),
      cnic: Yup.string().required("Required"),
      // emergencyContactCountryCode: Yup.string().required("Required"),
      emergencyContactCellNo: Yup.string().required("Required"),
      emergencyContactPersonName: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      role: Yup.number().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loadingHandler(true));
      const data = {
        propertyTypeId: values?.propertyTypeId,
        userDetailId: values?.userDetailID,
        name: values.name,
        cellNo: `${values.countryCode}${values.cellNo}`,
        address: values.address,
        cnic: values.cnic,
        emergencyContactPersonName: values.emergencyContactPersonName,
        emergencyContactCellNo: values.emergencyContactCellNo,
        roleId: values.role,
      };
      formsList.some((item) => item === "owner")
        ? api
            .updateUser({
              data,
            })
            .then((response) => {
              if (response?.data?.headers?.code === 200) {
                toast.success(response?.data?.headers?.msg);
              } else {
                toast.error(response?.data?.headers?.msg);
              }
            })
            .finally(() => {
              dispatch(loadingHandler(false));
            })
        : api
            .addUser({
              data: { ...data, communitySeriesDetailId, password: "12345" },
            })
            .then((response) => {
              if (response?.data?.headers?.code === 200) {
                toast.success(response?.data?.headers?.msg);
              } else {
                toast.error(response?.data?.headers?.msg);
              }
            })
            .finally(() => {
              dispatch(loadingHandler(false));
            });
      // window.location.reload();
    },
  });

  const tenantForm = useFormik({
    initialValues: tenantData,
    enableReinitialize: true,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      // countryCode: Yup.string().required("Required"),
      cellNo: Yup.string().required("Required"),
      cnic: Yup.string().required("Required"),
      // emergencyContactCountryCode: Yup.string().required("Required"),
      emergencyContactCellNo: Yup.string().required("Required"),
      emergencyContactPersonName: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      role: Yup.number().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loadingHandler(true));
      const data = {
        propertyTypeId: values?.propertyTypeId,
        userDetailId: values?.userDetailID,
        name: values.name,
        cellNo: `${values.countryCode}${values.cellNo}`,
        address: values.address,
        cnic: values.cnic,
        emergencyContactPersonName: values.emergencyContactPersonName,
        emergencyContactCellNo: values.emergencyContactCellNo,
        roleId: values.role,
      };
      console.log("formssdata", data);
      formsList.some((item) => item === "tenant")
        ? api
            .updateUser({
              data,
            })
            .then((response) => {
              if (response?.data?.headers?.code === 200) {
                toast.success(response?.data?.headers?.msg);
              } else {
                toast.error(response?.data?.headers?.msg);
              }
            })
            .finally(() => {
              dispatch(loadingHandler(false));
            })
        : api
            .addUser({
              data: { ...data, communitySeriesDetailId, password: "12345" },
            })
            .then((response) => {
              if (response?.data?.headers?.code === 200) {
                toast.success(response?.data?.headers?.msg);
              } else {
                toast.error(response?.data?.headers?.msg);
              }
            })
            .finally(() => {
              dispatch(loadingHandler(false));
            });
    },
  });

  function getAmmenityDetailBySeriesDetailId(id) {
    setCommunitySeriesDetailId(id);
    dispatch(loadingHandler(true));
    api
      ?.GetAmmenityDetailBySeriesDetailId({ id })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          if (response?.data?.body.length > 0) {
            const details = response?.data?.body[0];
            setAmenityDetails({
              ammenityName: details.ammenityName,
              unitName: details.ammenityPrefixList[0]?.dbSeriesList[0].unitName,
              prefix: details.ammenityPrefixList[0].prefix,
            });
            const userDetails =
              response?.data?.body[0].ammenityPrefixList[0].dbSeriesList[0]
                ?.userDetailList;
            if (userDetails !== null) {
              userDetails.map((item, index) => {
                if (item.propertyTypeID === 1) {
                  setFormsList((prev) => [...prev, "owner"]);
                  setOwnerData({
                    ...item,
                    role: item?.roleID,
                    cellNo: item?.cellNo?.slice(3, -1),
                    countryCode: item?.cellNo?.slice(1, 3),
                  });
                } else {
                  setFormsList((prev) => [...prev, "tenant"]);
                  setTenantData({
                    ...item,
                    role: item?.roleID,
                    cellNo: item?.cellNo?.slice(3, -1),
                    countryCode: item?.cellNo?.slice(1, 3),
                  });
                }
              });
            }
          }
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  }

  return {
    ownerForm,
    tenantForm,
    getAmmenityDetailBySeriesDetailId,
    formsList,
    amenityDetails,
  };
};

export default useUser;
