import React, { useState } from "react";
import classes from "./Union.module.css";
import { FaCircleArrowLeft } from "react-icons/fa6";
import SearchBox from "../../../components/SearchBox";
import MySelect from "../../../components/MySelect";
import { RxCaretSort } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import TabComponent from "../../../components/TabComponent";
import FilterButton from "../../../components/FilterButton";
import AmenitiesItemCard from "../Dashboard/AmenitiesDashboard/AmenitiesItemCard";
import UnionItemCard from "./UnionItemCard";
import DrawerWrapper from "../../../components/DrawerWrapper";
import AddUnionMember from "./AddUnionMember";
import { MdPersonAdd } from "react-icons/md";
import AddButton from "../../../components/AddButton";

const Union = () => {
  const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);

  const [activeFilters, setActiveFilters] = useState({
    president: true,
    tenant: false,
    owner: false,
    contact: false,
  });
  const [addForm, setAddForm] = useState({
    name: "",
    code: "",
    contact: "",
    nic: "",
    role: "",
    address: "",
  });

  const handleFilterChange = (name) => {
    setActiveFilters((prev) => ({
      ...prev,
      [name]: !{ ...prev }[name],
    }));
  };

  const handleAddUnionChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const flatsData = [
    {
      name: "Zone - A",
      flatNo: [
        { no: "301", type: "owner" },
        { no: "302", type: "tenant" },
        { no: "303", type: "" },
        { no: "304", type: "" },
        { no: "305", type: "" },
        { no: "306", type: "" },
        { no: "307", type: "" },
        { no: "308", type: "" },
        { no: "309", type: "" },
        { no: "310", type: "" },
      ],
    },
    {
      name: "Zone - B",
      flatNo: [
        { no: "301", type: "tenant" },
        { no: "302", type: "" },
        { no: "303", type: "" },
        { no: "304", type: "" },
        { no: "305", type: "" },
        { no: "306", type: "" },
        { no: "307", type: "" },
        { no: "308", type: "" },
        { no: "309", type: "" },
        { no: "310", type: "owner" },
      ],
    },
  ];
  const shopsData = [
    {
      name: "",
      flatNo: [
        { no: "301", type: "tenant" },
        { no: "302", type: "tenant" },
        { no: "303", type: "" },
        { no: "304", type: "" },
        { no: "305", type: "" },
        { no: "306", type: "" },
        { no: "307", type: "" },
        { no: "308", type: "" },
        { no: "309", type: "" },
        { no: "310", type: "" },
      ],
    },
  ];

  const memberData = [
    {
      name: "Muhammad Owais",
      flatNo: "308",
      block: "A",
      floor: "3rd",
      type: "Owner",
      code: "",
      contact: "",
      position: "President",
    },
    {
      name: "Saad Ashraf",
      flatNo: "408",
      block: "B",
      floor: "4th",
      type: "Tenant",
      code: "",
      contact: "",
      position: "Mananger",
    },
    {
      name: "Haris Ali",
      flatNo: "408",
      block: "B",
      floor: "4th",
      type: "Outsider",
      code: "",
      contact: "",
      position: "Supervisor",
    },
  ];
  const navigateHandler = (item) => {
    navigate(`/member/${item.flatNo}`, { state: { position: item?.position } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenAddModal(false);
  };

  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["community-dropdown"]}>
        <SearchBox label={"Search"} name={"search"} />
        <AddButton handler={() => setOpenAddModal(true)} />
      </div>
      <div className={classes["tabs-section"]}>
        <FilterButton
          text={"President"}
          active={activeFilters.president}
          onClick={() => handleFilterChange("president")}
        />
        <FilterButton
          text={"Tenants"}
          active={activeFilters.tenant}
          onClick={() => handleFilterChange("tenant")}
        />
        <FilterButton
          text={"Owner"}
          active={activeFilters.owner}
          onClick={() => handleFilterChange("owner")}
        />
        <FilterButton
          text={"Contact"}
          active={activeFilters.contact}
          onClick={() => handleFilterChange("contact")}
        />
      </div>
      <div className={classes["subSection"]}>
        <div className={classes["members-heading"]}>
          <h1>Union Member</h1>
          <p>
            {memberData?.length < 9
              ? `0${memberData?.length}`
              : memberData?.length}{" "}
            Records
          </p>
        </div>
        {memberData?.map((item, index) => (
          <UnionItemCard
            item={item}
            key={index}
            contact={activeFilters.contact}
            onClick={navigateHandler}
          />
        ))}
      </div>
      {(activeFilters.tenant || activeFilters.owner) && (
        <>
          <div className={classes["subSection"]}>
            <div className={classes["sub-heading"]}>
              <h1>Flats</h1>
              {flatsData?.length > 1 && (
                <p>
                  Sorting{" "}
                  <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
                </p>
              )}
            </div>
            <AmenitiesItemCard data={flatsData} />
          </div>
          <div className={classes["subSection"]}>
            <div className={classes["sub-heading"]}>
              <h1>Shops</h1>
              {shopsData?.length > 1 && (
                <p>
                  Sorting{" "}
                  <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
                </p>
              )}
            </div>
            <AmenitiesItemCard data={shopsData} />
          </div>
        </>
      )}
      <DrawerWrapper
        headingText="Add new union member"
        open={openAddModal}
        setOpen={setOpenAddModal}
        btnText={"Save"}
        handleSubmit={handleSubmit}
      >
        <AddUnionMember form={addForm} handleChange={handleAddUnionChange} />
      </DrawerWrapper>
    </div>
  );
};

export default Union;
