import React from "react";
import classes from "./AmenitiesDashboard.module.css";
import { FaCircleArrowLeft } from "react-icons/fa6";
import SearchBox from "../../../../components/SearchBox";
import MySelect from "../../../../components/MySelect";
import TabComponent from "../../../../components/TabComponent";
import { RxCaretSort } from "react-icons/rx";
import AmenitiesItemCard from "./AmenitiesItemCard";
import { useNavigate } from "react-router-dom";

const AmenitiesDashboard = () => {
  const navigate = useNavigate();
  const flatsData = [
    {
      name: "Zone - A",
      flatNo: [
        { no: "301", type: "owner" },
        { no: "302", type: "tenant" },
        { no: "303", type: "" },
        { no: "304", type: "" },
        { no: "305", type: "" },
        { no: "306", type: "" },
        { no: "307", type: "" },
        { no: "308", type: "" },
        { no: "309", type: "" },
        { no: "310", type: "" },
      ],
    },
    {
      name: "Zone - B",
      flatNo: [
        { no: "301", type: "tenant" },
        { no: "302", type: "" },
        { no: "303", type: "" },
        { no: "304", type: "" },
        { no: "305", type: "" },
        { no: "306", type: "" },
        { no: "307", type: "" },
        { no: "308", type: "" },
        { no: "309", type: "" },
        { no: "310", type: "owner" },
      ],
    },
  ];
  const shopsData = [
    {
      name: "",
      flatNo: [
        { no: "301", type: "tenant" },
        { no: "302", type: "tenant" },
        { no: "303", type: "" },
        { no: "304", type: "" },
        { no: "305", type: "" },
        { no: "306", type: "" },
        { no: "307", type: "" },
        { no: "308", type: "" },
        { no: "309", type: "" },
        { no: "310", type: "" },
      ],
    },
  ];
  return (
    <div className={classes["mainWrapper"]}>
      <div style={{ margin: "0px" }} className="c-p">
        <FaCircleArrowLeft
          color={"var(--main-color)"}
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={classes["searchWrapper"]}>
        <SearchBox label={"Search"} name={"search"} />
      </div>
      <div className={classes["tabs-section"]}>
        <TabComponent text={"Blocks"} isOpen={true} />
        <TabComponent text={"Floors"} isOpen={true} />
        <TabComponent text={"Flats"} isOpen={true} />
        <TabComponent text={"Zones"} isOpen={true} />
      </div>
      <div className={classes["subSection"]}>
        <div className={classes["sub-heading"]}>
          <h1>Flats</h1>
          {flatsData?.length > 1 && (
            <p>
              Sorting <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
            </p>
          )}
        </div>
        <AmenitiesItemCard data={flatsData} />
      </div>
      <div className={classes["subSection"]}>
        <div className={classes["sub-heading"]}>
          <h1>Shops</h1>
          {shopsData?.length > 1 && (
            <p>
              Sorting <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
            </p>
          )}
        </div>
        <AmenitiesItemCard data={shopsData} />
      </div>
    </div>
  );
};

export default AmenitiesDashboard;
