import React from "react";
import MainSettings from "./MainSettings";
import classes from "./Settings.module.css";

const Settings = () => {
  return (
    <div className={classes["mainWrapper"]}>
      <MainSettings />
    </div>
  );
};

export default Settings;
