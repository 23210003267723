import React from "react";
import classes from "./AmenitiesItemCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const AmenitiesItemCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className={classes["mainWrapper"]}>
      {/* {data?.map((item, index) => ( */}
      <>
        <div className={classes["header"]}>
          <p>{data[0]?.prefix}</p>
          <div className={classes["indicators"]}>
            <p>
              <GoDash size={20} style={{ strokeWidth: "1px" }} />
              Owner
            </p>
            <p>
              <GoDash size={20} style={{ strokeWidth: "1px" }} />
              Tenant
            </p>
          </div>
        </div>
        <div className={classes["layout"]}>
          {data?.map((item, index) => (
            <div
              className={[
                classes["flatItem"],
                item.type === "tenant"
                  ? classes["tenant"]
                  : item.type === "owner"
                  ? classes["owner"]
                  : "",
              ].join(" ")}
              onClick={() =>
                navigate(`/member/${item?.communitySeriesDetailId}`)
              }
            >
              {item.prefix ? `${item.prefix}-` : ""}
              {item.unitName}
            </div>
          ))}
        </div>
        {/* <span className={classes["expandSpan"]}></span> */}
      </>
      {/* ))} */}
    </div>
  );
};
export default AmenitiesItemCard;
