import React, { useState } from "react";
import classes from "./UnionItemCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../../components/MyInput";
import { FiPhoneOutgoing } from "react-icons/fi";

const UnionItemCard = ({ item, onClick, contact }) => {
  const [memberForm, setMemberForm] = useState({
    code: "",
    contact: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberForm((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["header"]} onClick={() => onClick(item)}>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div className={classes["detailsWrapper"]}>
            <div className={classes["userDetailsDiv"]}>
              <p>
                Flat/{item.flatNo} - Block {item?.block} - {item.floor} Floor
              </p>
              <p>{item?.name}</p>
            </div>
            <h5>{item.position}</h5>
          </div>
          <FaChevronRight size={20} />
        </div>
        <div
          className={[classes["indicators"], classes[`${item?.type}`]].join(
            " "
          )}
        >
          <p>
            <GoDash size={20} style={{ strokeWidth: "1px" }} />
            {item?.type}
          </p>
        </div>
      </div>
      {contact && (
        <Box
          component="form"
          // onSubmit={handleSubmit}
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={classes["formWrapper"]}
          noValidate
          autoComplete="off"
        >
          <Grid container columnGap={1}>
            <Grid xs={2.8}>
              <MyInput
                name="code"
                label="C-Code"
                value={memberForm?.code}
                setter={(e) => handleChange(e)}
              />
            </Grid>
            <Grid xs={9}>
              <MyInput
                name="contact"
                label="Number"
                value={memberForm?.contact}
                setter={(e) => handleChange(e)}
                leftIcon={
                  <FiPhoneOutgoing size={25} color="var(--black-color)" />
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};
export default UnionItemCard;
