import React from "react";
import styles from "./AuthLayout.module.css";
import { Outlet } from "react-router-dom";
import { background } from "../../assets/constant";

const AuthLayout = () => {
  return (
    <main className={styles.mainWrapper}>
      <Outlet />
    </main>
  );
};

export default AuthLayout;
