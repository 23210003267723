import React, { useState, Suspense } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Router from "./routes";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader";
const App = () => {
  const [check, setcheck] = useState(null);
  const [showModal, setShowModal] = useState(true);
  return (
    <>
      <ToastContainer />
      <HashRouter>
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
      </HashRouter>
    </>
  );
};

export default App;
