import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid } from "@mui/material";
import CommonCard from "../../../components/CommonCard";
import styles from "./Login.module.css";
import AuthMainComponent from "../../../components/AuthMainComponent";
import AuthSubComponent from "../../../components/AuthSubComponent";
import MyInput from "../../../components/MyInput";
import MyButton from "../../../components/MyButton";
import { useNavigate } from "react-router-dom";
import { login } from "../../../store/authSlice";
import LoginStep1 from "./LoginStep1";
import useLogin from "../../../Hooks/useLogin";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";

export default function Login() {
  const navigate = useNavigate();
  const loginHook = useLogin();
  const { loading } = useSelector((state) => state?.loadingReducer);

  const buttonHanler = () => {
    navigate("signup");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={0}>
          <AuthSubComponent
            text={"Don’t have an account? Sign up"}
            btnText={"Sign Up"}
            btnFunc={buttonHanler}
          />
          <AuthMainComponent className={styles["extraStyles"]}>
            <LoginStep1 login={loginHook} buttonHanler={buttonHanler} />
          </AuthMainComponent>
        </Grid>
      )}
    </>
  );
}
