import React, { useState } from "react";
import classes from "./ItemCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MyCheckBox from "../../../../components/MyCheckBox";

const ItemCard = ({ data, handler }) => {
  const navigate = useNavigate();
  return (
    <div className={classes["mainWrapper"]}>
      {data?.map((item, index) => (
        <>
          <div className={classes["header"]}>
            <p>{item?.name}</p>
            <div className={classes["indicators"]}>
              <p>
                <GoDash size={20} style={{ strokeWidth: "1px" }} />
                Alloted
              </p>
            </div>
          </div>
          <div className={classes["layout"]}>
            {item?.parkingNo?.map((item1, index) => (
              <div
                className={[
                  classes["flatItem"],
                  item1.alloted && classes["selected"],
                ].join(" ")}
                onClick={() => handler(item1, item1.alloted)}
              >
                {item1.no}
              </div>
            ))}
          </div>
        </>
      ))}
    </div>
  );
};
export default ItemCard;
