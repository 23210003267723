import React, { useRef, useState } from "react";
import { FaUser } from "react-icons/fa6";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { IoHome, IoNotifications } from "react-icons/io5";
import ShortcutItem from "../../../components/ShortcutItem";
import classes from "./Shortcuts.module.css";

const Shortcuts = ({ setIsSidebarClose }) => {
  const sidebarAside = useRef(null);
  const [markAsActive, setMarkAsActive] = useState(1);

  return (
    <aside
      className={[
        classes["sidebar-side-transition"],
        classes["sidebar__sideContent"],
      ].join(" ")}
      ref={sidebarAside}
    >
      <div className={classes["sidebarListBefore"]}></div>
      <div
        className={[
          classes["sidebar__mainMenu"],
          classes["overflow-x-hidden"],
        ].join(" ")}
      >
        <ul className={classes["sidebarList"]}>
          <ShortcutItem
            path={""}
            text={"Dashboard"}
            icon={<IoHome size={20} color="var(--secondary-color)" />}
            active={markAsActive === 1}
            setMarkAsActive={() => setMarkAsActive(1)}
          />

          <ShortcutItem
            path={"notifications"}
            text={""}
            listOfOptions={null}
            icon={
              <IoNotifications
                color="var(--secondary-color)"
                size={18}
                style={{ marginTop: 5 }}
              />
            }
            active={markAsActive === 2}
            setMarkAsActive={() => setMarkAsActive(2)}
          />
          <ShortcutItem
            path={"member"}
            text={""}
            listOfOptions={null}
            icon={
              <FaUser
                color="var(--secondary-color)"
                size={18}
                style={{ marginTop: 5 }}
              />
            }
            active={markAsActive === 3}
            setMarkAsActive={() => setMarkAsActive(3)}
          />
          <ShortcutItem
            text={""}
            listOfOptions={null}
            icon={
              <HiOutlineMenuAlt2
                color="var(--secondary-color)"
                size={18}
                style={{ marginTop: 5 }}
              />
            }
            active={markAsActive === 4}
            setMarkAsActive={setIsSidebarClose}
          />
        </ul>
      </div>
      <div className={classes["sidebarListAfter"]}></div>
    </aside>
  );
};

export default Shortcuts;
