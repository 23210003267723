import React from "react";
import MyButton from "../MyButton";
import classes from "./ApprovalCard.module.css";
import { amenityNameFormatter } from "../../helpers";

const ApprovalCard = ({ data, handler, isSetting = false, ...props }) => {
  const {
    approvalRequestId,
    requestedBy,
    requestedDate,
    name,
    requestTitle = "Join Request",
    remarks,
    ammenityName,
    prefix,
    unitName,
  } = data;
  const userAddress = amenityNameFormatter(ammenityName, prefix, unitName);
  const description = remarks.split("\n\t");
  return (
    <div
      className={classes["mainWrapper"]}
      onClick={() => handler && handler(approvalRequestId)}
    >
      <div className={classes["firstDiv"]}>
        <p>Request By {requestedBy}</p>
        <p>{requestedDate}</p>
      </div>
      <div className={classes["userDetailsDiv"]}>
        <p>{userAddress}</p>
        <p>{name}</p>
      </div>
      <div className={classes["complainDetailsDiv"]}>
        <p>{requestTitle}</p>
        <p>{description[0]}</p>
        <p>{description[1]}</p>
      </div>
      {isSetting && (
        <div className={classes["complainActionsDiv"]}>
          <MyButton
            className={classes["rejectBtn"]}
            handler={() => props.handleReject(approvalRequestId)}
          >
            Reject
          </MyButton>
          <MyButton
            className={classes["acceptBtn"]}
            handler={() => handler(approvalRequestId)}
          >
            View
          </MyButton>
        </div>
      )}
    </div>
  );
};

export default ApprovalCard;
