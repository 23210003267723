import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../../components/MyInput";
import classes from "./ParkingAllotedBSheet.module.css";
import { MdCancel } from "react-icons/md";
import MySelect from "../../../../components/MySelect";
import MyButton from "../../../../components/MyButton";
import DrawerWrapper from "../../../../components/DrawerWrapper";
import SearchBox from "../../../../components/SearchBox";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ParkingAllotedBSheet = ({
  open,
  setOpen,
  handleChange,
  form,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const options = [
    {
      label: "Member",
      value: "member",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ];
  return (
    <>
      <DrawerWrapper
        headingText="Alloted Parking"
        open={open}
        setOpen={setOpen}
        btnText={"Save & Allot"}
        handleSubmit={handleSubmit}
      >
        <Box
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={classes["formWrapper"]}
          noValidate
          autoComplete="off"
        >
          <MySelect
            name={"propertyType"}
            value={form?.propertyType}
            label="Property Type"
            setter={handleChange}
            options={options}
          />
        </Box>
        <p className={classes["cardHeading"]}>Assign Propperty</p>
        <Card
          name={form?.name}
          address={form?.address}
          handleClick={() => navigate(`/member/${form?.flatNo}`)}
        />
      </DrawerWrapper>
    </>
  );
};

export default ParkingAllotedBSheet;

const Card = ({ name, address, handleClick }) => {
  return (
    <div className={classes["cardMainWrapper"]} onClick={handleClick}>
      <div className={classes["userDetailsWrapper"]}>
        <div className={classes["detailsWrapper"]}>
          <div className={classes["userDetailsDiv"]}>
            <p>{address}</p>
            <p>{name}</p>
          </div>
        </div>
        <FaChevronRight size={20} />
      </div>
    </div>
  );
};
