import React from "react";
import { Grid } from "@mui/material";
import MyInput from "../MyInput";
import { RiDeleteBin6Line } from "react-icons/ri";

const AmenityAddComponent = ({
  item,
  handleChange,
  handleAddSeries,
  handleRemoveSeries,
  styles,
}) => {
  return (
    <div className={styles.formItem}>
      <h1>{item?.ammenityName}</h1>
      {item.communityAmmenitySeriesList.map((item1, index) =>
        item?.ammenityID !== 4 && item?.ammenityID !== 5 ? (
          <Grid
            container
            columnGap={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid xs={3}>
              <MyInput
                name={`prefix`}
                label="Prefix"
                value={item1?.prefix}
                setter={(e) => handleChange(item?.ammenityID, e, item1.id)}
              />
            </Grid>
            <Grid xs={3}>
              <MyInput
                name="seriesStart"
                label="Series Start"
                value={item1?.seriesStart}
                setter={(e) => handleChange(item?.ammenityID, e, item1.id)}
              />
            </Grid>
            <Grid xs={3}>
              <MyInput
                name="seriesEnd"
                label="Series End"
                value={item1?.seriesEnd}
                setter={(e) => handleChange(item?.ammenityID, e, item1.id)}
              />
            </Grid>
            <Grid xs={1}>
              <RiDeleteBin6Line
                color="var(--secondary-color)"
                size={40}
                className={[styles.deleteBtn, "c-p"].join(" ")}
                onClick={() => handleRemoveSeries(item?.ammenityID, item1.id)}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container columnGap={2}>
            <MyInput
              name={`name`}
              label="Name"
              value={item1?.name}
              setter={(e) => handleChange(item?.ammenityID, e, item1.id)}
              leftIcon={
                <RiDeleteBin6Line
                  color="var(--secondary-color)"
                  size={40}
                  className={[styles.deleteBtn, "c-p"].join(" ")}
                  onClick={() => handleRemoveSeries(item?.ammenityID, item1.id)}
                />
              }
            />
          </Grid>
        )
      )}

      <div>
        <span
          className={styles.addNew}
          onClick={() => handleAddSeries(item?.ammenityID)}
        >
          Add New
        </span>
      </div>
    </div>
  );
};

export default AmenityAddComponent;
